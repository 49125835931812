import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'
import moment from 'moment'


const ExecucoesServico = ({ states, setters, action, get }) => {


    const formatToMoney = value => {
        if (!value) return
        const valorFloat = value / 100;
        return valorFloat.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }


    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }


    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }




    useEffect(() => {
        if (states.modalTarefasShow === false) {
            setters.setTarefas({})
        }

    }, [states.modalTarefasShow])


    return (
        <>
            <Transition.Root show={states.modalTarefasShow} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setters.setModalTarefasShow(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {/* Mexer na altura, só colocar o height na classname abaixo */}
                                <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => setters.setModalTarefasShow(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className='flex-col border-b border-gray-900/10'>
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Dados do Evento</h3>
                                        <div className='flex justify-center flex-col items-center gap-x-4 pb-4'>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                Dados do evento Selecionado
                                            </p>
                                        </div>
                                    </div>
                                    <div className='pt-10 pl-10 pr-10'>
                                        <div className="space-y-12">
                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações Principais do evento</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do atendimento</p>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <div className="grid grid-cols-1 gap-y-6  lg:grid-cols-4 gap-x-6 ">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Dia</label>
                                                            <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                <p className="text-sm font-semibold leading-5 text-gray-700">{moment(states?.Tarefas?.data).format('DD/MM/YYYY')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">status</label>
                                                            <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                <p className="text-sm font-semibold leading-5 text-gray-700">{states?.Tarefas?.status === '0' ? "Agendado" : states?.Tarefas?.status === '1' ? "Cancelado" : states?.Tarefas?.status === '2' ? "Finalizado" : "Reagendado"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Tipo</label>
                                                            <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                <p className="text-sm font-semibold leading-5 text-gray-700">{states?.Tarefas?.tipo === '1' ? "Lembrete" : "Serviço"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Início</label>
                                                            <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                <p className="text-sm font-semibold leading-5 text-gray-700">{moment(states?.Tarefas?.inicio).format('HH:mm:ss')}</p>
                                                            </div>
                                                        </div>
                                                        {
                                                            (states?.Tarefas?.tipo === '1') && (
                                                                <div className="sm:col-span-2">
                                                                    <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Fim</label>
                                                                    <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                        <p className="text-sm font-semibold leading-5 text-gray-700">{moment(states?.Tarefas?.fim).format('HH:mm:ss')}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            (states?.Tarefas?.tipo === '1') && (
                                                                <div className="sm:col-span-2">
                                                                    <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Enviar para o cliente?</label>
                                                                    <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                        <p className="text-sm font-semibold leading-5 text-gray-700">{states?.Tarefas?.cpf !== null ? "Sim" : "Não"}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Título</label>
                                                            <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                <p className="text-sm font-semibold leading-5 text-gray-700">{states?.Tarefas?.titulo}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Descrição</label>
                                                            <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                <p className="text-sm font-semibold leading-5 text-gray-700">{states?.Tarefas?.descricao}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações adicionais do eveto</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados adicionais</p>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-4 ">
                                                        <div className="flex flex-col">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Psicologo</label>
                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{states.Tarefas.prestador_nome}</p>
                                                        </div>
                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">CPF</label>
                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{formatToCpfOrCnpj(states.Tarefas.prestador_cpf)}</p>
                                                        </div>
                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{formatToPhoneBrl(states.Tarefas.prestador_telefone)}</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        (states?.Tarefas?.tipo === '0' || states?.Tarefas?.cpf !== null) &&
                                                        <>
                                                            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-4 mt-12">
                                                                <div className="flex flex-col">
                                                                    <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                                    <p className="text-sm font-semibold leading-5 text-gray-900">{states.Tarefas.nome}</p>
                                                                </div>
                                                                <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                    <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Cpf</label>
                                                                    <p className="text-sm font-semibold leading-5 text-gray-900">{formatToCpfOrCnpj(states.Tarefas.cpf)}</p>
                                                                </div>
                                                                <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                    <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">E-mail</label>
                                                                    <p className="text-sm font-semibold leading-5 text-gray-900">{states.Tarefas.email}</p>
                                                                </div>
                                                                <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                    <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                                                    <p className="text-sm font-semibold leading-5 text-gray-900">{formatToPhoneBrl(states.Tarefas.telefone)}</p>
                                                                </div>
                                                            </div>
                                                        </>


                                                    }
                                                    {
                                                        (states?.Tarefas?.tipo === '0') &&
                                                        <div className="sm:col-span-3 mt-12">
                                                            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-4">
                                                                <div className="flex flex-col">
                                                                    <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Serviço</label>
                                                                    <p className="text-sm font-semibold leading-5 text-gray-900">{states.Tarefas.servico_nome}</p>
                                                                </div>
                                                                <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                    <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Duração</label>
                                                                    <p className="text-sm font-semibold leading-5 text-gray-900">{states.Tarefas.servico_duracao}</p>
                                                                </div>
                                                                <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                    <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Preço</label>
                                                                    <p className="text-sm font-semibold leading-5 text-gray-900">{formatToMoney(states.Tarefas.servico_preco)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 flex flex-col sm:flex-row items-center justify-end gap-x-6">
                                            <button type="button" className="text-sm mt-3 sm:mt-0 font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                                onClick={() => setters.setModalTarefasShow(false)}>
                                                Fechar
                                            </button>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root >
        </>
    )

}


export default ExecucoesServico;