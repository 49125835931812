import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import AuthProvider from './Context/index';
import AuthProviderApi from './Context/api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalInformacao from './Components/Modal/msgData'
import ModalInformacao2 from './Components/Modal/msgData2'
import SpinnerOverlay from './Components/SpinnerOverlay';


function App() {


  return (
    <div className='notranslate'>
      <AuthProvider>
        <AuthProviderApi>
          <BrowserRouter>
            <ToastContainer />
            <ModalInformacao />
            <ModalInformacao2 />
            <SpinnerOverlay />
            <Router />
          </BrowserRouter>
        </AuthProviderApi>
      </AuthProvider>
    </div>

  );
}

export default App;