import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from "react";
import RouterWrapper from './auth';



//NO AUTH
import Login from '../Views/Login';
import RecuperarSenha from '../Views/RecuperacaoSenha'
import Page404 from '../Views/404'
import TrocaEmail from '../Views/TrocaEmail'
import Autenticar from '../Views/AutenticarEmail'


//AUTH
import Dashboard from '../Views/Dashboard';

import Perfil from '../Views/Perfil'


import Servicos from '../Views/Servicos'
import MinhaAgenda from '../Views/MinnhaAgenda'
import Conversas from '../Views/Conversas'
import Conversa from '../Views/Conversa'

const Router = () => {

    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])



    return (
        <Routes>
            <Route path="/servicos/:token" element={<RouterWrapper component={Servicos} isPrivate={true} />} />
            <Route path="/minhaAgenda/:token" element={<RouterWrapper component={MinhaAgenda} isPrivate={true} />} />
            <Route path="/conversas/:token" element={<RouterWrapper component={Conversas} isPrivate={true} />} />
            <Route path="/conversas/:toke/conversa/:id" element={<RouterWrapper component={Conversa} isPrivate={true} />} />
            <Route path="/perfil/:token" element={<RouterWrapper component={Perfil} isPrivate={true} />} />
            <Route path="/autenticarEmail/:token" element={<RouterWrapper component={Autenticar} isPrivate={false} />} />
            <Route path="/recuperarSenha/:cliente/:token" element={<RouterWrapper component={RecuperarSenha} isPrivate={false} />} />            
            <Route path="/dashboard/:token" element={<RouterWrapper component={Dashboard} isPrivate={true} />} />
            <Route path="/plataforma/:token" element={<RouterWrapper component={Login} isPrivate={false} />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
    )
}

export default Router