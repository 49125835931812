import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { InformationCircleIcon, ArrowLeftIcon } from '@heroicons/react/20/solid'
import Painel from '../../Components/PainelChat/Index'
import ModalShow from '../../Components/Modal/ExecucaoServico'
import moment from 'moment'




const ClienteSecundario = () => {

    const { states, setters } = useContext(AuthContext)
    const {


        getPacientConversa,
        getPacientConversaMensagens,
        postMensagemConversa
    } = useContext(AuthContextApi)
    const { token, id, idConversa } = useParams()
    const navigate = useNavigate()


    const primeiroLoad = async () => {
        await getPacientConversa(id)
        await getPacientConversaMensagens(id)

    }


    const dowloadArquivo = data => {
        setters.setModalUsuarios(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja baixar o arquivo anexado na conversa?`)
        setters.setDescModalMsg(`Após a confirmação o arquivo será baixado`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const link = document.createElement("a");
            link.href = `data:application/pdf;base64,${data.arquivo_path}`;
            link.download = data.arquivo_nome;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setters.setModalMsg(false)
        })
    }


    const dowloadImagem = data => {
        setters.setModalUsuarios(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja baixar a imagem anexada na conversa?`)
        setters.setDescModalMsg(`Após a confirmação a imagem será baixada`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const link = document.createElement("a");
            link.href = `data:application/pdf;base64,${data.imagem_path}`;
            link.download = data.imagem_nome;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setters.setModalMsg(false)
        })
    }

    const loadMoreMSg = async () => {
        if (states.limiteBuscaConversaPaciente) {
            return
        }
        setters.setOffsetConversaPaciente(states.offsetConversaPaciente + states.limiteConversaPaciente)
    }

    const sendMessage = async (data) => {
        await postMensagemConversa({
            ...data,
            comunicacao_id: id,
        }, id)
    }

    useEffect(() => {
        primeiroLoad()

        return () => {
            setters.setDataConversaPaciente([])
            setters.setConversaPaciente({})
            setters.setOffsetConversaPaciente(0)
            setters.setLimiteBuscaConversaPaciente(false)
        }

    }, [])

    useEffect(() => {
        if (states.offsetConversaPaciente > 0) {
            getPacientConversaMensagens(idConversa)
        }
    }, [states.offsetConversaPaciente])


    return (
        <>
            <div className="flex flex-col">

                <div className="flex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                    <div className='flex flex items-center justify-between'>
                        <button onClick={() => navigate(-1)} className="flex h-[50%] items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                            <ArrowLeftIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                        </button>
                    </div>
                    <h1 className="text-base font-semibold leading-6 text-gray-900 mt-4">Dados da conversa</h1>
                    <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Título: </p>
                            <p className="text-md text-gray-700 mt-2">{states.ConversaPaciente.titulo}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Descrição:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.ConversaPaciente.descricao}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Data Criação:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{moment(states.ConversaPaciente.data_criacao).format('DD/MM/YYYY')}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Data Atualização:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{moment(states.ConversaPaciente.data_atualizacao).format('DD/MM/YYYY HH:mm')}</p>
                        </div>
                    </div>
                    {
                        (states.ConversaPaciente.imagem_nome !== null || states.ConversaPaciente.arquivo_nome !== null || states.ConversaPaciente.link !== null) && (
                            <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                                {
                                    states.ConversaPaciente.imagem_nome !== null && (
                                        <div className='flex flex-col  mt-2 p-1'>
                                            <p className="text-md text-gray-700">Imagem: </p>
                                            <button onClick={() => dowloadImagem(states.ConversaPaciente)} className="flex h-[50%] mt-4 items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                                                <InformationCircleIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                <span>Baixar Imagem</span>
                                            </button>
                                        </div>
                                    )
                                }
                                {
                                    states.ConversaPaciente.arquivo_nome !== null && (
                                        <div className='flex flex-col  mt-2 p-1'>
                                            <p className="text-md text-gray-700">Arquivo:</p>
                                            <button onClick={() => dowloadArquivo(states.ConversaPaciente)} className="flex h-[50%] mt-4 items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                                                <InformationCircleIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                <span>Baixar Arquivo</span>
                                            </button>
                                        </div>
                                    )
                                }
                                {
                                    states.ConversaPaciente.link !== null && (
                                        <div className='flex flex-col  mt-2 p-1'>
                                            <p className="text-md text-gray-700">Link:</p>
                                            <a href={states.ConversaPaciente.link} target="_blank" rel="noopener noreferrer" className="flex h-[50%] mt-4 items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                                                <InformationCircleIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                <span>Abrir Link</span>
                                            </a>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>

                <div className="flex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                    <div className='flex flex-col sm:flex-row w-full  items-between justify-between p-1'>
                        <Painel
                            initialMessages={states.dataConversaPaciente}
                            states={states}
                            setters={setters}
                            loadMoreMessages={loadMoreMSg}
                            send={sendMessage}
                        />

                    </div>
                </div>




            </div>
            <ModalShow states={states} setters={setters} />
        </>
    )

}



export default ClienteSecundario;