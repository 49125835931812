import { createContext, useState } from "react";
import { jsonConfig } from '../Config';
import moment from 'moment'

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const [signed, setSigned] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')

    const [modalMsg, setModalMsg] = useState(false)
    const [msgModalMsg, setMsgModalMsg] = useState('')
    const [descModalMsg, setDescModalMsg] = useState('')
    const [titleButtonModalMsg, setTitleButtonModalMsg] = useState('Retornar')
    const [titleButtonCloseModalMsg, setTitleButtonCloseModalMsg] = useState('Fechar')
    const [perigoModalMsg, setPerigoModalMsg] = useState(false)
    const [functionModalMsg, setFunctionModalMsg] = useState(() => { })

    const [modalMsg2, setModalMsg2] = useState(false)
    const [msgModalMsg2, setMsgModalMsg2] = useState('')
    const [descModalMsg2, setDescModalMsg2] = useState('')
    const [titleButtonModalMsg2, setTitleButtonModalMsg2] = useState('Retornar')
    const [titleButtonCloseModalMsg2, setTitleButtonCloseModalMsg2] = useState('Fechar')
    const [perigoModalMsg2, setPerigoModalMsg2] = useState(false)
    const [functionModalMsg2, setFunctionModalMsg2] = useState(() => { })
    const [functionBtnModalMsg2, setFunctionBtnModalMsg2] = useState(() => { })

    const [sideBar, setSideBar] = useState(false)

    const [disableNavigation, setDisableNavigation] = useState(false)




    const [dataEstatisticaDashBoard, setDataEstatisticaDashBoard] = useState([])


    //#region Acesso do cliente 
    const [msgErrorAcessoCliente, setMsgErrorAcessoCliente] = useState('')
    const [errorAcessoCliente, setErrorAcessoCliente] = useState(false)
    const [clientePossuiConfig, setClientePossuiConfig] = useState(false)
    const [clienteConfig, setClienteConfig] = useState({})

    const [categoriaServico, setCategoriaServico] = useState({})
    const [configuracaoContabilidade, setConfiguracaoContabilidade] = useState({})
    const [modalConfiguracao, setModalConfiguracao] = useState(false)
    //#endregion


    const [dataEstatisticaCarneLeao, setDataEstatisticaCarneLeao] = useState([])
    const [dataCarneLeao, setDataCarneLeao] = useState([])
    const [CarneLeao, setCarneLeao] = useState({})
    const [offsetCarneLeao, setOffsetCarneLeao] = useState(0)
    const [limiteCarneLeao, setLimiteCarneLeao] = useState(jsonConfig.limitDefault)
    const [limiteBuscaCarneLeao, setLimiteBuscaCarneLeao] = useState(false)

    const [dataEstatisticaFaturas, setDataEstatisticaFaturas] = useState([])
    const [dataFaturas, setDataFaturas] = useState([])
    const [Faturas, setFaturas] = useState({})
    const [offsetFaturas, setOffsetFaturas] = useState(0)
    const [limiteFaturas, setLimiteFaturas] = useState(jsonConfig.limitDefault)
    const [limiteBuscaFaturas, setLimiteBuscaFaturas] = useState(false)

    const [dataEstatisticaUsuarios, setDataEstatisticaUsuarios] = useState([])
    const [dataUsuarios, setDataUsuarios] = useState([])
    const [Usuarios, setUsuarios] = useState({})
    const [offsetUsuarios, setOffsetUsuarios] = useState(0)
    const [limiteUsuarios, setLimiteUsuarios] = useState(jsonConfig.limitDefault)
    const [limiteBuscaUsuarios, setLimiteBuscaUsuarios] = useState(false)
    const [modalUsuarios, setModalUsuarios] = useState(false)
    const [modalUsuariosShow, setModalUsuariosShow] = useState(false)


    const [dataEstatisticaRecuperacaoSenha, setDataEstatisticaRecuperacaoSenha] = useState([])
    const [dataRecuperacaoSenha, setDataRecuperacaoSenha] = useState([])
    const [RecuperacaoSenha, setRecuperacaoSenha] = useState({})
    const [offsetRecuperacaoSenha, setOffsetRecuperacaoSenha] = useState(0)
    const [limiteRecuperacaoSenha, setLimiteRecuperacaoSenha] = useState(jsonConfig.limitDefault)
    const [limiteBuscaRecuperacaoSenha, setLimiteBuscaRecuperacaoSenha] = useState(false)

    const [dataEstatisticaCategorias, setDataEstatisticaCategorias] = useState([])
    const [dataCategorias, setDataCategorias] = useState([])
    const [dataCategoriasForm, setDataCategoriasForm] = useState([])
    const [Categorias, setCategorias] = useState({})
    const [offsetCategorias, setOffsetCategorias] = useState(0)
    const [limiteCategorias, setLimiteCategorias] = useState(jsonConfig.limitDefault)
    const [limiteBuscaCategorias, setLimiteBuscaCategorias] = useState(false)
    const [modalCategorias, setModalCategorias] = useState(false)
    const [modalCategoriasShow, setModalCategoriasShow] = useState(false)


    const [dataEstatisticaServicos, setDataEstatisticaServicos] = useState([])
    const [dataServicos, setDataServicos] = useState([])
    const [Servicos, setServicos] = useState({})
    const [offsetServicos, setOffsetServicos] = useState(0)
    const [limiteServicos, setLimiteServicos] = useState(jsonConfig.limitDefault)
    const [limiteBuscaServicos, setLimiteBuscaServicos] = useState(false)
    const [modalServicos, setModalServicos] = useState(false)
    const [modalServicosShow, setModalServicosShow] = useState(false)

    const [dataEstatisticaPrestadores, setDataEstatisticaPrestadores] = useState([])
    const [dataPrestadores, setDataPrestadores] = useState([])
    const [Prestadores, setPrestadores] = useState({})
    const [offsetPrestadores, setOffsetPrestadores] = useState(0)
    const [limitePrestadores, setLimitePrestadores] = useState(jsonConfig.limitDefault)
    const [limiteBuscaPrestadores, setLimiteBuscaPrestadores] = useState(false)
    const [modalPrestadores, setModalPrestadores] = useState(false)
    const [modalPrestadoresShow, setModalPrestadoresShow] = useState(false)

    const [dataEstatisticaExecucoesServico, setDataEstatisticaExecucoesServico] = useState([])
    const [dataExecucoesServico, setDataExecucoesServico] = useState([])
    const [ExecucoesServico, setExecucoesServico] = useState({})
    const [offsetExecucoesServico, setOffsetExecucoesServico] = useState(0)
    const [limiteExecucoesServico, setLimiteExecucoesServico] = useState(jsonConfig.limitDefault)
    const [limiteBuscaExecucoesServico, setLimiteBuscaExecucoesServico] = useState(false)
    const [modalExecucoesServico, setModalExecucoesServico] = useState(false)
    const [modalExecucoesServicoShow, setModalExecucoesServicoShow] = useState(false)

    const [dataEstatisticaPacientes, setDataEstatisticaPacientes] = useState([])
    const [dataPacientes, setDataPacientes] = useState([])
    const [Pacientes, setPacientes] = useState({})
    const [offsetPacientes, setOffsetPacientes] = useState(0)
    const [limitePacientes, setLimitePacientes] = useState(jsonConfig.limitDefault)
    const [limiteBuscaPacientes, setLimiteBuscaPacientes] = useState(false)
    const [modalPacientes, setModalPacientes] = useState(false)
    const [modalPacientesShow, setModalPacientesShow] = useState(false)

    const [dataEstatisticaPaciente, setDataEstatisticaPaciente] = useState([])
    const [dataPaciente, setDataPaciente] = useState([])
    const [Paciente, setPaciente] = useState({})
    const [offsetPaciente, setOffsetPaciente] = useState(0)
    const [limitePaciente, setLimitePaciente] = useState(jsonConfig.limitDefault)
    const [limiteBuscaPaciente, setLimiteBuscaPaciente] = useState(false)
    const [modalPaciente, setModalPaciente] = useState(false)
    const [modalPacienteShow, setModalPacienteShow] = useState(false)


    const [dataExecucoesServicoPaciente, setDataExecucoesServicoPaciente] = useState([])
    const [ExecucoesServicoPaciente, setExecucoesServicoPaciente] = useState({})
    const [offsetExecucoesServicoPaciente, setOffsetExecucoesServicoPaciente] = useState(0)
    const [limiteExecucoesServicoPaciente, setLimiteExecucoesServicoPaciente] = useState(jsonConfig.limitDefault)
    const [limiteBuscaExecucoesServicoPaciente, setLimiteBuscaExecucoesServicoPaciente] = useState(false)
    const [modalExecucoesServicoPaciente, setModalExecucoesServicoPaciente] = useState(false)
    const [modalExecucoesServicoPacienteShow, setModalExecucoesServicoPacienteShow] = useState(false)

    const [dataProntuariosPaciente, setDataProntuariosPaciente] = useState([])
    const [ProntuariosPaciente, setProntuariosPaciente] = useState({})
    const [offsetProntuariosPaciente, setOffsetProntuariosPaciente] = useState(0)
    const [limiteProntuariosPaciente, setLimiteProntuariosPaciente] = useState(jsonConfig.limitDefault)
    const [limiteBuscaProntuariosPaciente, setLimiteBuscaProntuariosPaciente] = useState(false)
    const [modalProntuariosPaciente, setModalProntuariosPaciente] = useState(false)
    const [modalProntuariosPacienteShow, setModalProntuariosPacienteShow] = useState(false)

    const [dataConversasPaciente, setDataConversasPaciente] = useState([])
    const [ConversasPaciente, setConversasPaciente] = useState({})
    const [offsetConversasPaciente, setOffsetConversasPaciente] = useState(0)
    const [limiteConversasPaciente, setLimiteConversasPaciente] = useState(jsonConfig.limitDefault)
    const [limiteBuscaConversasPaciente, setLimiteBuscaConversasPaciente] = useState(false)
    const [modalConversasPaciente, setModalConversasPaciente] = useState(false)

    const [dataConversaPaciente, setDataConversaPaciente] = useState([])
    const [ConversaPaciente, setConversaPaciente] = useState({})
    const [offsetConversaPaciente, setOffsetConversaPaciente] = useState(0)
    const [limiteConversaPaciente, setLimiteConversaPaciente] = useState(jsonConfig.limitDefaultMsg)
    const [limiteBuscaConversaPaciente, setLimiteBuscaConversaPaciente] = useState(false)

    const [dataCategoriaPrestador, setDataCategoriaPrestador] = useState([])
    const [CategoriaPrestador, setCategoriaPrestador] = useState({})
    const [offsetCategoriaPrestador, setOffsetCategoriaPrestador] = useState(0)
    const [limiteCategoriaPrestador, setLimiteCategoriaPrestador] = useState(jsonConfig.limitDefault)
    const [limiteBuscaCategoriaPrestador, setLimiteBuscaCategoriaPrestador] = useState(false)

    const [prontuario, setProntuario] = useState({})

    const [dataPacientePrestador, setDataPacientePrestador] = useState([])
    const [PacientePrestador, setPacientePrestador] = useState({})
    const [offsetPacientePrestador, setOffsetPacientePrestador] = useState(0)
    const [limitePacientePrestador, setLimitePacientePrestador] = useState(jsonConfig.limitDefault)
    const [limiteBuscaPacientePrestador, setLimiteBuscaPacientePrestador] = useState(false)
    const [modalPacientePrestador, setModalPacientePrestador] = useState(false)

    const [dataAgenda, setDataAgenda] = useState([])
    const [Agenda, setAgenda] = useState({})
    const [month, setMonth] = useState(moment().format('YYYY-MM'))

    const [dataTarefas, setDataTarefas] = useState([])
    const [Tarefas, setTarefas] = useState({})
    const [offsetTarefas, setOffsetTarefas] = useState(0)
    const [limiteTarefas, setLimiteTarefas] = useState(jsonConfig.limitDefault)
    const [limiteBuscaTarefas, setLimiteBuscaTarefas] = useState(false)
    const [day, setDay] = useState(moment().format('YYYY-MM-DD'))
    const [modalTarefas, setModalTarefas] = useState(false)
    const [modalTarefasShow, setModalTarefasShow] = useState(false)

    const [meuDashboard, setMeuDashboard] = useState([])
    const [meuPerfil, setMeuPerfil] = useState({})


    const states = {
        meuDashboard,
        meuPerfil,
        modalMsg2,
        msgModalMsg2,
        descModalMsg2,
        titleButtonModalMsg2,
        titleButtonCloseModalMsg2,
        perigoModalMsg2,
        functionModalMsg2,
        functionBtnModalMsg2,
        modalTarefasShow,
        modalTarefas,
        day,
        month,
        dataAgenda,
        Agenda,
        dataTarefas,
        Tarefas,
        offsetTarefas,
        limiteTarefas,
        limiteBuscaTarefas,
        modalPacientePrestador,
        dataPacientePrestador,
        PacientePrestador,
        offsetPacientePrestador,
        limitePacientePrestador,
        limiteBuscaPacientePrestador,
        disableNavigation,
        prontuario,
        dataCategoriaPrestador,
        CategoriaPrestador,
        offsetCategoriaPrestador,
        limiteCategoriaPrestador,
        limiteBuscaCategoriaPrestador,
        modalConversasPaciente,
        dataConversaPaciente,
        ConversaPaciente,
        offsetConversaPaciente,
        limiteConversaPaciente,
        limiteBuscaConversaPaciente,
        dataConversasPaciente,
        ConversasPaciente,
        offsetConversasPaciente,
        limiteConversasPaciente,
        limiteBuscaConversasPaciente,
        dataProntuariosPaciente,
        ProntuariosPaciente,
        offsetProntuariosPaciente,
        limiteProntuariosPaciente,
        limiteBuscaProntuariosPaciente,
        modalProntuariosPacienteShow,
        modalProntuariosPaciente,
        dataExecucoesServicoPaciente,
        ExecucoesServicoPaciente,
        offsetExecucoesServicoPaciente,
        limiteExecucoesServicoPaciente,
        limiteBuscaExecucoesServicoPaciente,
        modalExecucoesServicoPacienteShow,
        modalExecucoesServicoPaciente,
        dataEstatisticaPaciente,
        dataPaciente,
        Paciente,
        offsetPaciente,
        limitePaciente,
        limiteBuscaPaciente,
        modalPacienteShow,
        modalPaciente,
        dataEstatisticaPacientes,
        dataPacientes,
        Pacientes,
        offsetPacientes,
        limitePacientes,
        limiteBuscaPacientes,
        modalPacientesShow,
        modalPacientes,
        dataEstatisticaExecucoesServico,
        dataExecucoesServico,
        ExecucoesServico,
        offsetExecucoesServico,
        limiteExecucoesServico,
        limiteBuscaExecucoesServico,
        modalExecucoesServicoShow,
        modalExecucoesServico,
        dataEstatisticaPrestadores,
        dataPrestadores,
        Prestadores,
        offsetPrestadores,
        limitePrestadores,
        limiteBuscaPrestadores,
        modalPrestadoresShow,
        modalPrestadores,
        dataCategoriasForm,
        dataEstatisticaServicos,
        dataServicos,
        Servicos,
        offsetServicos,
        limiteServicos,
        limiteBuscaServicos,
        modalServicosShow,
        modalServicos,
        dataEstatisticaCategorias,
        dataCategorias,
        Categorias,
        offsetCategorias,
        limiteCategorias,
        limiteBuscaCategorias,
        modalCategoriasShow,
        modalCategorias,
        dataEstatisticaRecuperacaoSenha,
        dataRecuperacaoSenha,
        RecuperacaoSenha,
        offsetRecuperacaoSenha,
        limiteRecuperacaoSenha,
        limiteBuscaRecuperacaoSenha,
        modalUsuariosShow,
        dataEstatisticaUsuarios,
        dataUsuarios,
        Usuarios,
        offsetUsuarios,
        limiteUsuarios,
        limiteBuscaUsuarios,
        modalUsuarios,
        modalConfiguracao,
        configuracaoContabilidade,
        categoriaServico,
        clientePossuiConfig,
        clienteConfig,
        errorAcessoCliente,
        msgErrorAcessoCliente,
        dataEstatisticaDashBoard,
        dataEstatisticaFaturas,
        dataFaturas,
        Faturas,
        offsetFaturas,
        limiteFaturas,
        limiteBuscaFaturas,
        dataEstatisticaCarneLeao,
        dataCarneLeao,
        CarneLeao,
        offsetCarneLeao,
        limiteCarneLeao,
        limiteBuscaCarneLeao,
        signed,
        isLoading,
        email,
        senha,
        modalMsg,
        msgModalMsg,
        descModalMsg,
        perigoModalMsg,
        titleButtonModalMsg,
        functionModalMsg,
        titleButtonCloseModalMsg,
        sideBar,
        isEdit
    }

    const setters = {
        setMeuDashboard,
        setMeuPerfil,
        setModalMsg2,
        setMsgModalMsg2,
        setDescModalMsg2,
        setTitleButtonModalMsg2,
        setTitleButtonCloseModalMsg2,
        setPerigoModalMsg2,
        setFunctionModalMsg2,
        setFunctionBtnModalMsg2,
        setModalTarefasShow,
        setModalTarefas,
        setDay,
        setMonth,
        setDataAgenda,
        setAgenda,
        setDataTarefas,
        setTarefas,
        setOffsetTarefas,
        setLimiteTarefas,
        setLimiteBuscaTarefas,
        setModalPacientePrestador,
        setDataPacientePrestador,
        setPacientePrestador,
        setOffsetPacientePrestador,
        setLimitePacientePrestador,
        setLimiteBuscaPacientePrestador,
        setDisableNavigation,
        setProntuario,
        setDataCategoriaPrestador,
        setCategoriaPrestador,
        setOffsetCategoriaPrestador,
        setLimiteCategoriaPrestador,
        setLimiteBuscaCategoriaPrestador,
        setModalConversasPaciente,
        setDataConversaPaciente,
        setConversaPaciente,
        setOffsetConversaPaciente,
        setLimiteConversaPaciente,
        setLimiteBuscaConversaPaciente,
        setDataConversasPaciente,
        setConversasPaciente,
        setOffsetConversasPaciente,
        setLimiteConversasPaciente,
        setLimiteBuscaConversasPaciente,
        setDataProntuariosPaciente,
        setProntuariosPaciente,
        setOffsetProntuariosPaciente,
        setLimiteProntuariosPaciente,
        setLimiteBuscaProntuariosPaciente,
        setModalProntuariosPacienteShow,
        setModalProntuariosPaciente,
        setDataExecucoesServicoPaciente,
        setExecucoesServicoPaciente,
        setOffsetExecucoesServicoPaciente,
        setLimiteExecucoesServicoPaciente,
        setLimiteBuscaExecucoesServicoPaciente,
        setModalExecucoesServicoPacienteShow,
        setModalExecucoesServicoPaciente,
        setDataEstatisticaPaciente,
        setDataPaciente,
        setPaciente,
        setOffsetPaciente,
        setLimitePaciente,
        setLimiteBuscaPaciente,
        setModalPacienteShow,
        setModalPaciente,
        setDataEstatisticaPacientes,
        setDataPacientes,
        setPacientes,
        setOffsetPacientes,
        setLimitePacientes,
        setLimiteBuscaPacientes,
        setModalPacientesShow,
        setModalPacientes,
        setDataEstatisticaExecucoesServico,
        setDataExecucoesServico,
        setExecucoesServico,
        setOffsetExecucoesServico,
        setLimiteExecucoesServico,
        setLimiteBuscaExecucoesServico,
        setModalExecucoesServicoShow,
        setModalExecucoesServico,
        setDataEstatisticaPrestadores,
        setDataPrestadores,
        setPrestadores,
        setOffsetPrestadores,
        setLimitePrestadores,
        setLimiteBuscaPrestadores,
        setModalPrestadoresShow,
        setModalPrestadores,
        setDataCategoriasForm,
        setDataEstatisticaServicos,
        setDataServicos,
        setServicos,
        setOffsetServicos,
        setLimiteServicos,
        setLimiteBuscaServicos,
        setModalServicosShow,
        setModalServicos,
        setDataEstatisticaCategorias,
        setDataCategorias,
        setCategorias,
        setOffsetCategorias,
        setLimiteCategorias,
        setLimiteBuscaCategorias,
        setModalCategoriasShow,
        setModalCategorias,
        setDataEstatisticaRecuperacaoSenha,
        setDataRecuperacaoSenha,
        setRecuperacaoSenha,
        setOffsetRecuperacaoSenha,
        setLimiteRecuperacaoSenha,
        setLimiteBuscaRecuperacaoSenha,
        setModalUsuariosShow,
        setDataEstatisticaUsuarios,
        setDataUsuarios,
        setUsuarios,
        setOffsetUsuarios,
        setLimiteUsuarios,
        setLimiteBuscaUsuarios,
        setModalUsuarios,
        setModalConfiguracao,
        setConfiguracaoContabilidade,
        setCategoriaServico,
        setClientePossuiConfig,
        setClienteConfig,
        setErrorAcessoCliente,
        setMsgErrorAcessoCliente,
        setDataEstatisticaDashBoard,
        setDataEstatisticaFaturas,
        setDataFaturas,
        setFaturas,
        setOffsetFaturas,
        setLimiteFaturas,
        setLimiteBuscaFaturas,
        setDataEstatisticaCarneLeao,
        setDataCarneLeao,
        setCarneLeao,
        setOffsetCarneLeao,
        setLimiteCarneLeao,
        setLimiteBuscaCarneLeao,
        setSigned,
        setIsLoading,
        setEmail,
        setSenha,
        setModalMsg,
        setMsgModalMsg,
        setDescModalMsg,
        setPerigoModalMsg,
        setTitleButtonModalMsg,
        setFunctionModalMsg,
        setTitleButtonCloseModalMsg,
        setSideBar,
        setIsEdit
    }




    return (
        <AuthContext.Provider
            value={{
                states,
                setters
            }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;
