import React, { useState } from 'react';
import moment from 'moment';

const PainelDeChat = ({ states, loadMoreMessages, send }) => {
    const [texto, setTexto] = useState('');

    const handleSendMessage = () => {
        if (texto === '') return;
        send({ texto });
        setTexto('');
    };


    return (
        <div className="flex-1 p-6 border hover:shadow-md mt-5 rounded-md">
            <div className='flex items-center justify-between mb-4'>
                <h1 className="text-base font-semibold leading-6 text-gray-900">Mensagens</h1>
                {
                    (!states.limiteBuscaConversaPaciente && states.dataConversaPaciente.length > 0) && (
                        <button onClick={loadMoreMessages} className="text-sm text-blue-500 hover:text-blue-700">Carregar mais</button>
                    )
                }
            </div>
            <div className='flex flex-col w-full h-96 overflow-y-auto p-1 border rounded-md'>
                {states.dataConversaPaciente.length > 0 ? (
                    states.dataConversaPaciente.map((message, index) => {
                        if (message.criado_por === null) {
                            return (
                                <div key={index} className='flex flex-col w-full items-end mt-2 p-2 '>
                                    <div className='flex items-center justify-between w-[50%] border p-4 rounded-[10px]'>
                                        <p className="text-md text-gray-700">{message.texto}</p>
                                        <p className="text-sm text-gray-500">{moment(message.data_criacao).format('DD/MM/YYYY HH:mm')}</p>
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div key={index} className='flex flex-col w-full items-start mt-2 p-2 '>
                                <div className='flex items-center justify-between w-[50%] border p-4 rounded-[10px]'>
                                    <p className="text-md text-gray-700">{message.texto}</p>
                                    <p className="text-sm text-gray-500">{moment(message.data_criacao).format('DD/MM/YYYY HH:mm')}</p>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div className='flex flex-col mt-2 p-1'>
                        <p className="text-md text-gray-700">Nenhuma mensagem registrada</p>
                    </div>
                )}
            </div>
            <div className='flex mt-4'>
                {
                    states.ConversaPaciente.aberto === 1 ? (
                        <>
                            <input
                                type="text"
                                value={texto}
                                onChange={(e) => setTexto(e.target.value)}
                                onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                                className="flex-1 p-2 border rounded-l-md"
                                placeholder="Digite sua mensagem..."
                            />
                            <button
                                onClick={handleSendMessage}
                                className="p-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-700"
                            >
                                Enviar
                            </button>
                        </>
                    ) : (
                        <p className="text-sm text-gray-500">Conversa encerrada</p>
                    )
                }
            </div>
        </div>
    );
};


export default PainelDeChat;