import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'




const ExecucoesServico = ({ states, setters }) => {




    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }


    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }




    useEffect(() => {
        if (states.modalExecucoesServicoShow === false) {
            setters.setExecucoesServico({})
        }

    }, [states.modalExecucoesServicoShow])


    return (
        <>
            <Transition.Root show={states.modalExecucoesServicoShow} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setters.setModalExecucoesServicoShow(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {/* Mexer na altura, só colocar o height na classname abaixo */}
                                <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => setters.setModalExecucoesServicoShow(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className='flex-col border-b border-gray-900/10'>
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Execução de Serviço</h3>
                                        <div className='flex justify-center flex-col items-center gap-x-4 pb-4'>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                Informações sobre a execução do serviço: <span className="font-semibold">{states.ExecucoesServico.nome_servico}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='pt-10 pl-10 pr-10'>
                                        <div className="space-y-12">

                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações das partes</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do paciente e do psicólogo.</p>
                                                </div>
                                                <div className="sm:col-span-2">

                                                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 ">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Psicólogo</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{states.ExecucoesServico.nome_prestador}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">CPF Psicólogo</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{formatToCpfOrCnpj(states.ExecucoesServico.cpf_prestador)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Registro</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{states.ExecucoesServico.entidadeRegistro} - {states.ExecucoesServico.codigoEntidadeRegistro}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1 sm:col-span-2 lg:col-span-3">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Contato</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{formatToPhoneBrl(states.ExecucoesServico.telefone_prestador)} - {states.ExecucoesServico.email_prestador}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 mt-4">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Paciente</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{states.ExecucoesServico.nome}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">CPF</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{formatToCpfOrCnpj(states.ExecucoesServico.cpf)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Registro</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{states.ExecucoesServico.entidadeRegistro} - {states.ExecucoesServico.codigoEntidadeRegistro}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1 sm:col-span-2 lg:col-span-3">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Contato</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{formatToPhoneBrl(states.ExecucoesServico.telefone)} - {states.ExecucoesServico.email}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Detalhes</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados da execução do serviço.</p>
                                                </div>
                                                <div className="sm:col-span-2">


                                                    <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 mt-6">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{states.ExecucoesServico.nome_servico}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Fim do Serviço</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{
                                                                    (states.ExecucoesServico.fim_servico !== null) ? moment(states.ExecucoesServico.fim_servico).format('DD/MM/YYYY HH:mm') : 'Não finalizado'
                                                                }</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Status</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                                                    {
                                                                         states.ExecucoesServico.status === 0 ? <span className="text-nlue-500">Aberto</span> : states.ExecucoesServico.status === 1 ? <span className="text-yellow-500">Em andamento</span> : <span className="text-green-500">Finalizado</span>
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 gap-x-6 mt-4">                                                        
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Notas</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{states.ExecucoesServico.notas}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2  mt-8">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Início</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="text-sm leading-6 text-gray-600">{moment(states.ExecucoesServico.inicio).format('DD/MM/YYYY HH:mm')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Fim Previsto</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="text-sm leading-6 text-gray-600">{moment(states.ExecucoesServico.fim).format('DD/MM/YYYY HH:mm')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Criacao</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="text-sm leading-6 text-gray-600">{moment(states.ExecucoesServico.data_criacao).format('DD/MM/YYYY')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Atualização</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="text-sm leading-6 text-gray-600">{moment(states.ExecucoesServico.data_atualizacao).format('DD/MM/YYYY')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="mt-6 flex items-center justify-end gap-x-6">
                                            <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                                onClick={() => setters.setModalExecucoesServicoShow(false)}>
                                                Fechar
                                            </button>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}


export default ExecucoesServico;