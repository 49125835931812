import moment from "moment"
import { useContext, useEffect } from "react"
import { AuthContext } from "../../Context"

const Footer = () => {
    const { states, setters } = useContext(AuthContext)

    useEffect(() => {
        if (states.errorAcessoCliente === true) {
            setters.setClientePossuiConfig(false)
            setters.setClienteConfig({})
        } else {
            setters.setErrorAcessoCliente(false)
            if (states.clientePossuiConfig === true) {
                setters.setClientePossuiConfig(true)
                setters.setClienteConfig(JSON.parse(localStorage.getItem('clienteConfig')))
            }
        }
    }, [states.errorAcessoCliente, states.clientePossuiConfig])

    return (
        <footer className="flex min-h-full" aria-labelledby="footer-heading">
            <div className="mx-auto max-w-7xl ">
                <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-2">
                    {
                        states.clientePossuiConfig ? (<>
                            <p className="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
                                &copy; {moment().format('YYYY')} {states.clienteConfig.cliente_nome} - Todos os direitos reservados.
                            </p>
                        </>) : (<>
                            <p className="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
                                &copy; {moment().format('YYYY')} SOTER TEC - Todos os direitos reservados.
                            </p>
                        </>)
                    }
                </div>
            </div>
        </footer>
    )
}

export default Footer
