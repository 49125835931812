import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Table from '../../Components/Table/MeusServicos';
import ModalShow from '../../Components/Modal/ExecucaoServico'


const ExecucoesServico = () => {
    const { states, setters } = useContext(AuthContext)
    const {
        getExecucaoServicoDataSistemaPaciente,
        getExecucaoServicoPaciente,
        getMeusDados
    } = useContext(AuthContextApi)
    const [query, setQuery] = useState('')
    const { token } = useParams()
    const navigate = useNavigate()




    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }
    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }


    const primeiroLoad = async () => {
        await getExecucaoServicoDataSistemaPaciente(token)
        await getExecucaoServicoPaciente()
        await getMeusDados()
    }

    const loadUsuarios = async () => {
        await getExecucaoServicoPaciente()

    }

    const next = async () => {
        if (states.limiteBuscaExecucoesServico) {
            if (states.offsetExecucoesServico + states.limiteExecucoesServico > 0) return
            setters.setLimiteBuscaExecucoesServico(true)
        }
        setters.setOffsetExecucoesServico(states.offsetExecucoesServico + states.limiteExecucoesServico)
    }

    const previous = async () => {
        if (states.offsetExecucoesServico - states.limiteExecucoesServico < 0) return
        if (states.limiteBuscaExecucoesServico) {
            setters.setLimiteBuscaExecucoesServico(false)
        }
        setters.setOffsetExecucoesServico(states.offsetExecucoesServico - states.limiteExecucoesServico)
    }



    const showServico = async (data) => {
        setters.setExecucoesServico(data)
        setters.setModalExecucoesServicoShow(true)
    }


    const dowloadComprovante = data => {
        setters.setModalUsuarios(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja baixar o comprovante de execução do serviço?`)
        setters.setDescModalMsg(`Após a confirmação o comprovante será baixado`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const link = document.createElement("a");
            link.href = `data:application/pdf;base64,${data.path}`;
            link.download = data.nomeArquivo;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setters.setModalMsg(false)
        })
    }




    useEffect(() => {
        primeiroLoad()

        return () => {
            setQuery('')
            setters.setDataEstatisticaExecucoesServico([])
            setters.setDataExecucoesServico([])
            setters.setDataCategoriasForm([])
            setters.setExecucoesServico({})
            setters.setOffsetExecucoesServico(0)
            setters.setLimiteBuscaExecucoesServico(false)
            setters.setDataCategoriaPrestador([])
            setters.setCategoriaPrestador({})
            setters.setOffsetCategoriaPrestador(0)
            setters.setLimiteBuscaCategoriaPrestador(false)
            setters.setPaciente({})

        }

    }, [])



    useEffect(() => {
        if (states.dataExecucoesServico?.length === 0) return
        loadUsuarios()
    }, [states.offsetExecucoesServico])




    const headers = ['', 'Status', 'Nome do serviço', 'Nome do psicólogo', 'Cpf do psicólogo', 'Início', 'Fim', 'Data atualização', 'Data de criação']
    return (
        <>
            <div className="flex flex-col">
                <div className="flex-1 p-4 border rounded-[10px]  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Painel de atendimentos</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações sobre seus atendimentos
                    </p>
                </div>
                <div className="flex-1 p-6 border  hover:shadow-md mt-5">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Meus Dados</h1>
                    <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                        <div className='flex flex-col   p-1'>
                            <p className="text-sm text-gray-700">{states.meuPerfil?.nome} - {formatToCpfOrCnpj(states.meuPerfil?.cpf)} <br /> {formatToPhoneBrl(states.meuPerfil?.telefone)}</p>
                        </div>
                        <div className='flex flex-col   p-1'>
                            <p className="text-sm text-gray-700 ">{states.meuPerfil?.rua} - {states.meuPerfil?.numero}, {states.meuPerfil?.complemento} <br /> {states.meuPerfil?.bairro} - {formatCep(states.meuPerfil?.cep)}</p>
                        </div>
                        <div className='flex flex-col   p-1'>
                            <p className="text-sm text-gray-700 "> {states.meuPerfil?.cidade} - {states.meuPerfil?.estado} </p>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                    {states.dataEstatisticaExecucoesServico?.length > 0 && states.dataEstatisticaExecucoesServico.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {
                    states.dataExecucoesServico?.length > 0 ? (
                        <>
                            <div className=" p-4 border hover:shadow-md mt-5">
                                {
                                    states.dataExecucoesServico?.length > 0 &&
                                    <Table title='Atendimentos' description='Registro dos atendimentos'
                                        next={next} show={showServico} dowload={dowloadComprovante}
                                        previous={previous} states={states} setters={setters} headers={headers}
                                        infoTable={{ offset: states.offsetExecucoesServico, limit: states.limiteExecucoesServico }} dataTable={states.dataExecucoesServico} query={query} />
                                }
                            </div>
                        </>
                    ) : (
                        <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Sem registro de serviços realizados</h1>
                        </div>
                    )
                }


            </div>
            <ModalShow states={states} setters={setters} />
        </>
    )

}



export default ExecucoesServico;