import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../Context";
import Sidebar from "../Components/SideBar";
import Navbar from "../Components/NavBar";
import Footer from "../Components/Footer";

const RouterWrapper = ({ component: Component, isPrivate }) => {
    const { states, setters } = useContext(AuthContext)
    const navigate = useNavigate()
    const { token } = useParams()


    const isSigned = async () => {
        const tokenLogin = localStorage.getItem('token')
        if (tokenLogin === null) {
            setters.setSigned(false)
        } else {
            setters.setSigned(true)
        }
    }

    useEffect(() => {
        isSigned()
    }, [localStorage.getItem('token')])


    if (isPrivate) {
        if (states.signed) {
            const haveConfig = localStorage.getItem('haveConfig')
            const haveCategoria = localStorage.getItem('haveCategoria')
            const havePrestador = localStorage.getItem('havePrestador')
            if (haveConfig === null && haveCategoria === null && havePrestador === null) {
                return (
                    <>
                        <Sidebar />
                        <Navbar />
                        <div className="flex flex-1 flex-col pb-16 bg-white">
                            <div className='flex flex-1 flex-col md:pl-64 mb-4'>
                                <div className="p-4">
                                    <Component />
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </>
                )
            } else {
                return (
                    <>
                        <div className="p-4">
                            <Component />
                        </div>
                        <Footer />
                    </>
                )
            }
        } else {
            if (localStorage.getItem('token') === null) {
                localStorage.removeItem('token')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                return (
                    <Navigate to={`/plataforma/${token}`} />
                )
            } else {
                const haveConfig = localStorage.getItem('haveConfig')
                const haveCategoria = localStorage.getItem('haveCategoria')
                const havePrestador = localStorage.getItem('havePrestador')
                if (haveConfig === null && haveCategoria === null && havePrestador === null) {
                    return (
                        <>
                            <Sidebar />
                            <Navbar />
                            <div className="flex flex-1 flex-col pb-16 bg-white">
                                <div className='flex flex-1 flex-col md:pl-64 mb-4'>
                                    <div className="p-4">
                                        <Component />
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </>
                    )
                } else {
                    return (
                        <>
                            <div className="p-4">
                                <Component />
                            </div>
                            <Footer />
                        </>
                    )
                }
            }
        }
    }

    if (isPrivate && !states.signed) {
        localStorage.removeItem('token')
        localStorage.removeItem('permisssion')
        localStorage.removeItem('usuario')
        localStorage.removeItem('nome')
        localStorage.removeItem('iamPrestador')
        localStorage.removeItem('empresa')
        localStorage.removeItem('haveConfig')
        localStorage.removeItem('havePrestador')
        localStorage.removeItem('haveCategoria')
        localStorage.removeItem('haveContabilidadeConfig')
        localStorage.removeItem('haveServicos')
        localStorage.removeItem('perfil')
        return (
            <Navigate to={`/plataforma/${token}`} />
        )
    }

    if (isPrivate && states.signed) {
        return (
            <Navigate to='/dashboard' />
        )
    }

    if (!isPrivate) {
        return (
            <Component />
        )
    }
    return (
        <Component />
    )



}

export default RouterWrapper;