import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { CheckCircleIcon, ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import moment from 'moment';

const Perfil = () => {

    const { states, setters } = useContext(AuthContext)
    const { getMeusDados, updateMyPerfil, profileChangeSenha, profileChangeEmail } = useContext(AuthContextApi)
    const [emailUsuario, setEmailUsuario] = useState('')
    const [cumprimento, setCumprimento] = useState('')
    const [cheron, setCheron] = useState(false)
    const [trocarSenha, setTrocarSenha] = useState(false)
    const { token } = useParams()

    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [novaSenha, setNovaSenha] = useState('')
    const [confirmarSenha, setConfirmarSenha] = useState('')
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorEmailMessage, setErrorEmailMessage] = useState('Campo obrigatório')
    const [errorSenha, setErrorSenha] = useState(false)
    const [errorSenhaMensagem, setErrorSenhaMensagem] = useState('Campo obrigatório')
    const [errorNovaSenha, setErrorNovaSenha] = useState(false)
    const [errorConfirmarSenha, setErrorConfirmarSenha] = useState(false)

    const [chevronPerfil, setChevronPerfil] = useState(true)

    const [errorNome, setErrorNome] = useState(false)
    const [errorTelefone, setErrorTelefone] = useState(false)
    const [errorCep, setErrorCep] = useState(false)
    const [errorNumero, setErrorNumero] = useState(false)
    const [errorComplemento, setErrorComplemento] = useState(false)





    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }

    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }


    const primeiroLoad = async () => {
        await getMeusDados()
    }


    const getGreeting = () => {
        const hour = moment().hour(); // Pega a hora atual
        if (hour < 12) {
            setCumprimento('Bom dia')
        } else if (hour < 18) {
            setCumprimento('Boa tarde')
        } else {
            setCumprimento('Boa noite')
        }
    };


    const actionButton = async () => {
        let error = false;


        if (senha === '') {
            setErrorSenha(true)
            setErrorSenhaMensagem('Campo obrigatório')
            error = true
        } else setErrorSenha(false)

        let obj = {};
        if (trocarSenha) {
            if (novaSenha === '') {
                setErrorNovaSenha(true)
                error = true
            } else setErrorNovaSenha(false)

            if (confirmarSenha === '') {
                setErrorConfirmarSenha(true)
                error = true
            } else setErrorConfirmarSenha(false)

            if (novaSenha !== confirmarSenha) {
                setErrorNovaSenha(true)
                setErrorSenhaMensagem('As senhas não coincidem')
                error = true
            } else {
                setErrorNovaSenha(false)
                setErrorSenhaMensagem('Campo obrigatório')
            }
            const isStrongerPassword = novaSenha.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
            if (!isStrongerPassword) {
                setErrorNovaSenha(true)
                setErrorSenhaMensagem('A senha deve conter no mínimo 8 caracteres, uma letra maiúscula e um número')
                error = true
            } else {
                setErrorNovaSenha(false)
                setErrorSenhaMensagem('Campo obrigatório')
            }
            obj = {
                senha: senha,
                novaSenha,
                confirmSenha: confirmarSenha
            }
        } else {
            if (email === '') {
                setErrorEmail(true)
                setErrorEmailMessage('Campo obrigatório')
                error = true
            } else setErrorEmail(false)

            const getMailStorage = localStorage.getItem('usuario')
            if (email === getMailStorage) {
                setErrorEmail(true)
                setErrorEmailMessage('O e-mail não pode ser igual ao atual')
                error = true
            } else {
                setErrorEmailMessage('Campo obrigatório')
            }
            obj = {
                email: email,
                senha: senha
            }

        }
        if (error) {
            error = false;
            return;
        }

        if (trocarSenha) {
            const resp = await profileChangeSenha(obj)
            if (resp.status === false) {
                setErrorSenha(true)
            } else {
                setTrocarSenha(false)
                setErrorSenha(false)
                setSenha('')
                setNovaSenha('')
                setConfirmarSenha('')
                setErrorSenhaMensagem('Campo obrigatório')
            }
        } else {
            const resp = await profileChangeEmail(obj)
            if (resp.status === false) {
                setErrorEmail(true)
            } else {
                setErrorEmail(false)
                setEmail('')
                setSenha('')
                setErrorEmailMessage('Campo obrigatório')
                localStorage.setItem('email', email)
            }
        }

    }


    const actionButtonPerfil = async () => {
        let error = false;


        if (states.meuPerfil.nome === '') {
            setErrorNome(true)
            error = true
        } else setErrorNome(false)

        if (states.meuPerfil.telefone === '') {
            setErrorTelefone(true)
            error = true
        } else setErrorTelefone(false)

        if (states.meuPerfil.cep === '') {
            setErrorCep(true)
            error = true
        } else setErrorCep(false)

        if (states.meuPerfil.numero === '') {
            setErrorNumero(true)
            error = true
        } else setErrorNumero(false)

        if (states.meuPerfil.complemento === '') {
            setErrorComplemento(true)
            error = true
        } else setErrorComplemento(false)



        if (error) {
            error = false;
            return;
        }

        await updateMyPerfil({ ...states.meuPerfil, cliente_id: token })
    }





    useEffect(() => {
        primeiroLoad()
        getGreeting()
        setEmailUsuario(localStorage.getItem('nome'))

        return () => {
            setters.setMeuPerfil({})
            setErrorCep(false)
            setErrorNome(false)
            setErrorNumero(false)
            setErrorTelefone(false)
            setErrorComplemento(false)
        }
    }, [])


    useEffect(() => {
        setErrorConfirmarSenha(false)
        setErrorEmail(false)
        setErrorNovaSenha(false)
        setErrorSenha(false)
        if (trocarSenha) {
            setEmail(localStorage.getItem('email'))
            setSenha('')
            setNovaSenha('')
            setConfirmarSenha('')
        } else {
            setEmail('')
            setSenha('')
            setNovaSenha('')
            setConfirmarSenha('')

        }
    }, [trocarSenha])





    const truncatFunction = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + '...' : str;
    }

    return (
        <div>

            <div className="flex-1 p-4 border  hover:shadow-md">
                <h1 className="hidden sm:flex text-2xl font-bold leading-7 text-gray-900 sm:leading-9">
                    {cumprimento}, {emailUsuario}
                </h1>
                <h1 className="flex sm:hidden text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                    {cumprimento}, {truncatFunction(emailUsuario, 18)}
                </h1>
                <CheckCircleIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                    aria-hidden="true"
                />
            </div>
            <div className="flex-1 p-6 border hover:shadow-md mt-5">
                <div className='flex items-between justify-between'>
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        <p>Informações do seu usuário</p>
                    </h1>
                    <button className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' onClick={() => setCheron(!cheron)}>
                        {cheron ? <ArrowDownIcon className="w-5 h-5" aria-hidden="true" /> : <ArrowUpIcon className="w-5 h-5" aria-hidden="true" />}
                    </button>
                </div>
                {cheron && (
                    <>
                        <div className="border-t border-1 border-gray-300 border-dashed my-4"></div>
                        <div className='flex flex-col  w-full '>
                            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1 mt-4'>
                                <div className='flex flex-col items-start border p-3 hover:shadow'>
                                    <p className="text-md text-blue-600 hover:text-blue-500" >{trocarSenha ? 'E-mail' : 'Digite um novo e-mail'}</p>
                                    <input
                                        type="text"
                                        className={trocarSenha ? 'border-2 bg-gray-100 p-2 rounded-[5px] w-full mt-3' : 'border p-2 rounded-[5px] w-full mt-3'}
                                        disabled={trocarSenha}
                                        placeholder='Digite um novo e-mail'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {
                                        (errorEmail) && (
                                            <p className='text-red-500 text-sm'>{errorEmailMessage}</p>
                                        )
                                    }

                                </div>
                            </div>
                            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1 mt-4'>
                                <div className='flex flex-col items-start border p-3 hover:shadow'>
                                    <p className="text-md text-blue-600 hover:text-blue-500">Senha</p>
                                    <input
                                        type="password"
                                        className='border p-2 rounded-[5px] w-full  mt-3'
                                        placeholder='Digite sua senha'
                                        value={senha}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                actionButton()
                                            }
                                        }}
                                        onChange={(e) => setSenha(e.target.value)}
                                    />
                                    {
                                        errorSenha && (
                                            <p className='text-red-500 text-sm'>{errorSenhaMensagem}</p>
                                        )
                                    }
                                </div>
                            </div>
                            {
                                trocarSenha && (
                                    <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1 mt-4'>
                                        <div className='flex flex-col items-start border p-3 hover:shadow'>
                                            <p className="text-md text-blue-600 hover:text-blue-500">Nova Senha</p>
                                            <input
                                                type="password"
                                                className='border p-2 rounded-[5px] w-full  mt-3'
                                                placeholder='Digite sua nova senha'
                                                value={novaSenha}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        actionButton()
                                                    }
                                                }}
                                                onChange={(e) => setNovaSenha(e.target.value)}
                                            />
                                            {
                                                errorNovaSenha && (
                                                    <p className='text-red-500 text-sm'>{errorSenhaMensagem}</p>
                                                )
                                            }
                                        </div>
                                        <div className='flex flex-col items-start border p-3 hover:shadow'>
                                            <p className="text-md text-blue-600 hover:text-blue-500">Confirme nova senha</p>
                                            <input
                                                type="password"
                                                className='border p-2 rounded-[5px] w-full  mt-3'
                                                placeholder='Digite sua nova senha'
                                                value={confirmarSenha}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        actionButton()
                                                    }
                                                }}
                                                onChange={(e) => setConfirmarSenha(e.target.value)}
                                            />
                                            {
                                                errorConfirmarSenha && (
                                                    <p className='text-red-500 text-sm'>Campo obrigatório</p>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="flex w-full">
                            <button className='flex ml-5 items-center justify-center border p-3 rounded-[10px] hover:shadow mt-4'
                                onClick={() => setTrocarSenha(!trocarSenha)}
                            >
                                {
                                    trocarSenha ? 'Trocar E-mail' : 'Trocar Senha'
                                }
                            </button>
                            <button className='flex items-center justify-center border p-3 rounded-[10px] hover:shadow mt-4 ml-4'
                                onClick={() => actionButton()}
                            >
                                Salvar Alterações
                            </button>
                        </div>
                    </>
                )}
            </div>
            <div className="flex-1 p-6 border hover:shadow-md mt-5">
                <div className='flex items-between justify-between'>
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        <p>Informações de perfil</p>
                    </h1>
                    <button className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' onClick={() => setChevronPerfil(!chevronPerfil)}>
                        {chevronPerfil ? <ArrowDownIcon className="w-5 h-5" aria-hidden="true" /> : <ArrowUpIcon className="w-5 h-5" aria-hidden="true" />}
                    </button>
                </div>
                {chevronPerfil && (
                    <>
                        <div className="border-t border-1 border-gray-300 border-dashed my-4"></div>
                        <div className='flex flex-col  w-full '>
                            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2 mt-4'>
                                <div className='flex flex-col items-start  p-3 hover:shadow'>
                                    <p className="text-md text-blue-600 hover:text-blue-500" >CPF</p>
                                    <input
                                        type="text"
                                        className="border p-2 rounded-[5px] w-full mt-3"
                                        placeholder='Digite seu CPF'
                                        value={formatToCpfOrCnpj(states?.meuPerfil?.cpf)}
                                        maxLength={14}
                                        onChange={(e) => {
                                            const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                            setters.setMeuPerfil({ ...states.meuPerfil, cpf: onlyNumbers })
                                        }}
                                        disabled
                                    />
                                </div>
                                <div className='flex flex-col items-start  p-3 hover:shadow'>
                                    <p className="text-md text-blue-600 hover:text-blue-500" >Nome</p>
                                    <input
                                        type="text"
                                        className='border p-2 rounded-[5px] w-full mt-3'
                                        placeholder='Digite seu nome'
                                        value={states?.meuPerfil?.nome}
                                        onChange={(e) => setters.setMeuPerfil({ ...states.meuPerfil, nome: e.target.value })}

                                    />
                                    {
                                        (errorNome) && <p className='text-red-500 text-sm'>Campo obrigatório</p>
                                    }
                                </div>
                                <div className='flex flex-col items-start  p-3 hover:shadow'>
                                    <p className="text-md text-blue-600 hover:text-blue-500" >Telefone</p>
                                    <input
                                        type="text"
                                        className="border p-2 rounded-[5px] w-full mt-3"
                                        placeholder='Digite seu telefone'
                                        value={formatToPhoneBrl(states?.meuPerfil?.telefone)}
                                        onChange={(e) => {
                                            const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                            setters.setMeuPerfil({ ...states.meuPerfil, telefone: onlyNumbers })
                                        }}
                                        maxLength={15}
                                    />
                                    {
                                        (errorTelefone) && <p className='text-red-500 text-sm'>Campo obrigatório</p>
                                    }
                                </div>
                                <div className='flex flex-col items-start  p-3 hover:shadow'>
                                    <p className="text-md text-blue-600 hover:text-blue-500" >CEP</p>
                                    <input
                                        type="text"
                                        className="border p-2 rounded-[5px] w-full mt-3"
                                        placeholder='Digite seu cep'
                                        value={formatCep(states?.meuPerfil?.cep)}
                                        onChange={(e) => {
                                            const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                            setters.setMeuPerfil({ ...states.meuPerfil, cep: onlyNumbers })

                                        }}
                                        maxLength={9}
                                    />
                                    {
                                        (errorCep) && <p className='text-red-500 text-sm'>Campo obrigatório</p>
                                    }
                                </div>
                                <div className='flex flex-col items-start  p-3 hover:shadow'>
                                    <p className="text-md text-blue-600 hover:text-blue-500" >Número residencial</p>
                                    <input
                                        type="text"
                                        className='border p-2 rounded-[5px] w-full mt-3'
                                        placeholder='Digite o número da sua residência'
                                        value={states?.meuPerfil?.numero}
                                        onChange={(e) => {
                                            const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                            setters.setMeuPerfil({ ...states.meuPerfil, numero: onlyNumbers })

                                        }}
                                    />
                                    {
                                        (errorNumero) && <p className='text-red-500 text-sm'>Campo obrigatório</p>
                                    }
                                </div>
                                <div className='flex flex-col items-start  p-3 hover:shadow'>
                                    <p className="text-md text-blue-600 hover:text-blue-500" >Complemeto</p>
                                    <input
                                        type="text"
                                        className='border p-2 rounded-[5px] w-full mt-3'
                                        placeholder='Digite o complemento'
                                        value={states?.meuPerfil?.complemento}
                                        onChange={(e) => setters.setMeuPerfil({ ...states.meuPerfil, complemento: e.target.value })}
                                    />
                                    {
                                        (errorComplemento) && <p className='text-red-500 text-sm'>Campo obrigatório</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full">
                            <button className='flex items-center justify-center border p-3 rounded-[10px] hover:shadow mt-4'
                                onClick={() => actionButtonPerfil()}
                            >
                                Salvar Alterações
                            </button>
                        </div>
                    </>
                )}
            </div>

        </div>
    )

}



export default Perfil;