// import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import {
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/20/solid'
import { useState, useEffect } from 'react'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const Calendario = ({ data, nextMonth, prevMonth, states, nextDay }) => {

    const [daysInMonth, setDaysInMonth] = useState(data.daysInMonth)

    const clickDay = (day) => {
        const newDays = daysInMonth.map((d) => {
            if (d.day === day.day) {
                d.isToday = true
            } else {
                d.isToday = false
            }
            return d
        });
        setDaysInMonth(newDays)
        nextDay(day)
    }


    useEffect(() => {
        setDaysInMonth(data.daysInMonth)

        return () => {
            setDaysInMonth([])
        }
    }, [])

    useEffect(() => {
        setDaysInMonth(data.daysInMonth)

    }, [states.dataAgenda])

    return (
        <div className="lg:flex lg:h-full lg:flex-col">
            <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                    <time dateTime="2022-01">{data.nameMonth} {data.year}</time>
                </h1>
                <div className="flex items-center">
                    <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                        <button
                            onClick={prevMonth}
                            type="button"
                            className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                        >
                            <span className="sr-only">Previous month</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        <button
                            type="button"
                            className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
                        >
                            {data.nameMonth}
                        </button>
                        <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                        <button
                            onClick={nextMonth}
                            type="button"
                            className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                        >
                            <span className="sr-only">Next month</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </header>
            <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                    <div className="bg-white py-2">
                        S<span className="sr-only sm:not-sr-only">áb</span>
                    </div>
                    <div className="bg-white py-2">
                        D<span className="sr-only sm:not-sr-only">om</span>
                    </div>
                    <div className="bg-white py-2">
                        S<span className="sr-only sm:not-sr-only">eg</span>
                    </div>
                    <div className="bg-white py-2">
                        T<span className="sr-only sm:not-sr-only">erc</span>
                    </div>
                    <div className="bg-white py-2">
                        Q<span className="sr-only sm:not-sr-only">ua</span>
                    </div>
                    <div className="bg-white py-2">
                        Q<span className="sr-only sm:not-sr-only">ui</span>
                    </div>
                    <div className="bg-white py-2">
                        S<span className="sr-only sm:not-sr-only">ex</span>
                    </div>
                </div>
                <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                    <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                        {daysInMonth?.map((day) => {
                            return (
                                <button
                                    key={day.date}
                                    className={classNames(
                                        (day.isThisMonth && !day.isToday) ? 'bg-white hover:bg-gray-100' : day.isToday ? 'bg-gray-450' :
                                            'bg-gray-100 text-gray-500',
                                        'relative px-3 py-2'
                                    )}
                                    onClick={() => clickDay(day)}
                                    disabled={!day.isThisMonth}
                                >
                                    <time
                                        dateTime={day.day}
                                        className={
                                            day.isToday
                                                ? 'flex h-6 w-6 items-center justify-center rounded-full bg-blue-600 font-semibold text-white'
                                                : undefined
                                        }
                                    >
                                        {day.day.split('-').pop().replace(/^0/, '')}
                                    </time>
                                    <div className="mt-2 min-h-[4rem] min-w-[8rem] flex flex-col items-center"> {/* Define a altura mínima aqui */}
                                        {day.holidays.length > 0 && (
                                            <ol className="w-full">
                                                {day.holidays.slice(0, 2).map((event) => (
                                                    <li key={event.id}>
                                                        <a href={event.href} className="group flex">
                                                            <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-blue-600">
                                                                {event.name}
                                                            </p>
                                                        </a>
                                                    </li>
                                                ))}
                                                {day.holidays.length > 2 && <li className="text-gray-500">+ {day.holidays.length - 2} more</li>}
                                            </ol>
                                        )}
                                        {day.hasEvent ? (
                                            <>
                                                <p className="absolute top-0 right-0 mt-1 mr-1 h-2 w-2 bg-blue-600 rounded-full" />
                                                <p>Você posuí {day.total} <br /> eventos</p>
                                            </>
                                        ) : (
                                            <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-blue-600">Sem eventos</p>
                                        )}
                                    </div>
                                </button>
                            )
                        })}
                    </div>
                    <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                        {daysInMonth?.map((day) => (
                            <button
                                key={day.day}
                                type="button"
                                onClick={() => clickDay(day)}
                                className={classNames(
                                    day.isThisMonth ? 'bg-white' : 'bg-gray-50',
                                    (day.isSelected || day.isToday) && 'font-semibold',
                                    day.isSelected && 'text-white',
                                    !day.isSelected && day.isToday && 'text-blue-600',
                                    !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                    !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                                    'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10'
                                )}
                            >
                                <time
                                    dateTime={day.day}
                                    className={classNames(
                                        day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                                        day.isSelected && day.isToday && 'bg-indigo-600',
                                        day.isSelected && !day.isToday && 'bg-gray-900',
                                        'ml-auto'
                                    )}
                                >
                                    {day.day.split('-').pop().replace(/^0/, '')}
                                </time>
                                <span className="sr-only">{day?.holidays?.length} events</span>
                                {day?.holidays?.length > 0 > 0 && (
                                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                        {day?.holidays?.map((event) => (
                                            <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                                        ))}
                                    </span>
                                )}
                                <span className="sr-only">{day?.hasEvent} events</span>
                                {day?.hasEvent && (
                                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                        <span  className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                                    </span>
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Calendario