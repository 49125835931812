import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { InformationCircleIcon, ArrowLeftIcon, TrashIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid'
import moment from 'moment'



const ConversasClienteSecundario = () => {

    const { states, setters } = useContext(AuthContext)
    const { 
        getMeusDados,
        getPacientConversas

    } = useContext(AuthContextApi)
    const { token } = useParams()
    const navigate = useNavigate()





    const primeiroLoad = async () => {
        await getMeusDados()
        await getPacientConversas()

    }

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }

    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }

    const loadMoreConversa = async () => {
        if (states.limiteBuscaConversasPaciente) return
        setters.setOffsetConversasPaciente(states.offsetConversasPaciente + states.limiteConversasPaciente)

    }


    useEffect(() => {
        primeiroLoad()

        return () => {
            setters.setDataConversasPaciente([])
            setters.setConversasPaciente({})
            setters.setOffsetConversasPaciente(0)
            setters.setLimiteBuscaConversasPaciente(false)

        }

    }, [])

    useEffect(() => {
        if (states.offsetConversasPaciente > 0) {
            getPacientConversas()
        }

    }, [states.offsetConversasPaciente])



    return (
        <>
            <div className="flex flex-col">


                <div className="flex flex-col sm:flex-row p-4 border hover:shadow-md rounded-md">
                    <div className='mt-5 ml-5'>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Conversas registradas</h1>
                    </div>
                </div>
                <div className="flex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Dados de paciente</h1>
                    <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Dados de cadastro: </p>
                            <p className="text-md text-gray-700 mt-2">{states.meuPerfil.nome} - {formatToCpfOrCnpj(states.meuPerfil.cpf)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Contato:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.meuPerfil.email} - {formatToPhoneBrl(states.meuPerfil.telefone)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Endereço:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.meuPerfil.rua}, {states.meuPerfil.numero} - {states.meuPerfil.bairro} - {formatCep(states.meuPerfil.cep)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Localização:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.meuPerfil.cidade} - {states.meuPerfil.estado}</p>
                        </div>
                    </div>
                </div>

                <div className="flex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                    <div className='flex items-center justify-between'>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Conversas Registradas</h1>
                    </div>
                    <div className='flex flex-col w-full  items-between justify-between p-1'>
                        {
                            states.dataConversasPaciente.length > 0 ? (
                                <>
                                    {
                                        states.dataConversasPaciente.map((conversa, index) => {
                                            return (
                                                <div key={index} className='flex flex-col border w-full  mt-2 p-1 rounded-md hover:shadow-md '>
                                                    <div className='flex items-center flex-col sm:flex-row justify-between p-4'>
                                                        <p className="text-md text-gray-700">Conversa: </p>
                                                        <p className="text-md text-gray-700 mt-2">{conversa.titulo}</p>
                                                    </div>
                                                    <div className='flex items-center flex-col sm:flex-row justify-between p-4'>
                                                        <p className="text-md text-gray-700 mt-2">Status da conversa:</p>
                                                        <p className="text-md text-gray-700 mt-2">{conversa.aberto === 1 ? <span className="text-green-500">Aberto</span> : <span className="text-red-500">Fechado</span>}</p>
                                                    </div>
                                                    <div className='flex items-center flex-col sm:flex-row justify-between p-4'>
                                                        <p className="text-md text-gray-700 mt-2">Data da conversa:</p>
                                                        <p className="text-md text-gray-700 mt-2">{moment(conversa.data_criacao).format('DD/MM/YYYY')}</p>
                                                    </div>
                                                    <div className='flex items-center flex-col sm:flex-row justify-between p-4'>
                                                        <button onClick={() => navigate(`/conversas/${token}/conversa/${conversa.id}`)} className="p-4 flex h-[50%] mt-2 sm:mt-0 items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                                                            <InformationCircleIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                            <span>Detalhes</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            ) : (
                                <div className='flex flex-col  mt-2 p-1'>
                                    <p className="text-md text-gray-700">Nenhuma conversa registrada</p>
                                </div>
                            )
                        }
                        {
                            (!states.limiteBuscaConversasPaciente && states.dataConversasPaciente.length > 0) && (
                                <div className='flex flex-col w-full items-center justify-center  mt-2 p-1'>
                                    <button onClick={loadMoreConversa} className="flex h-[50%] w-[10%] text-center items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                                        <span>Carregar mais</span>
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>




            </div>
        </>
    )

}



export default ConversasClienteSecundario;